import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import { Fragment } from 'react';

import DividerHorizontalDesktop from '@/core/components/divider/divider-horizontal-desktop';
import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { GroupV1 } from '@/features/_index/mobile';
import getFromPageComponentRegistryDesktop from '@/features/a-dynamic-page/components/page-component-registry-desktop';
import PageComponentSwitch from '@/features/a-dynamic-page/components/page-component-switch';
import { validateLayoutPosition } from '@/features/page-layouts/layout-validation-service';

type PageComponentSwitchWrapperDesktopProps = DynamicPageProps & {
    divider?: boolean;
};

export default function PageComponentSwitchWrapperDesktop({
    divider = false,
    ...props
}: PageComponentSwitchWrapperDesktopProps) {
    const pageComponentNames = props.components.map((component) => {
        return component['@type'];
    });

    return (
        <>
            {props.components.map((component, index) => {
                validateLayoutPosition(component);

                const hasDivider = index > 0 && divider;
                const componentType = component['@type'];
                const componentConfig = getFromPageComponentRegistryDesktop(componentType);

                if (componentType === 'GroupV1') {
                    return (
                        <Fragment key={`${getComponentId(component['@id'])}-${index}`}>
                            {hasDivider && (
                                <DividerHorizontalDesktop
                                    color={'alto'}
                                    marginBottom={0}
                                    marginTop={0}
                                />
                            )}
                            <GroupV1
                                componentId={getComponentId(component['@id'])}
                                components={component.attributes.components}
                                getComponentConfig={getFromPageComponentRegistryDesktop}
                                htmlTitle={component.attributes.htmlTitle}
                                isPageLoading={props.isPageLoading}
                                meta={component.meta}
                                title={component.attributes.title}
                            />
                        </Fragment>
                    );
                }

                return (
                    <Fragment key={`${getComponentId(component['@id'])}-${index}`}>
                        {hasDivider && (
                            <DividerHorizontalDesktop
                                color={'alto'}
                                marginBottom={0}
                                marginTop={0}
                            />
                        )}
                        <PageComponentSwitch
                            component={component}
                            componentConfig={componentConfig}
                            componentIndex={index}
                            isPageLoading={props.isPageLoading}
                            pageComponentNames={pageComponentNames}
                        />
                    </Fragment>
                );
            })}
        </>
    );
}
